import axios from "axios";

class RegistrationApi{

    async create(data) {
        let res;
        const errorText = 'Could not create';
        try {
          res = await axios.post('/registration', data);
          if (res.status === 200) {
            res = res.data;
          }
          else {
            console.error(errorText);
            res = false;
          }
        } catch (e) { 
          console.error(errorText);
          console.error(e);
          res = false;
        }
        return res;
    }


    async get() {
      const errorText = 'Could not create';
      let list;
      try {
        let url = '/';
        list = await axios.get(url);
        if (list.status === 200) {
          list = list.data;
        } else {
          console.error(errorText);
        }
      } catch (e) {
        console.error(errorText);
        console.error(e);
        list = null;
      }
      return list;
    }

    async checkLink(link) {
      const errorText = 'Could not create';
      let res;
      try {
        let url = `/link/${link}`;
        res = await axios.get(url);
        if (res.status === 200) {
          res = res.data;
        } else {
          console.error(errorText);
        }
      } catch (e) {
        console.error(errorText);
        console.error(e);
        res = null;
      }
      return res;
    }


}
const registrationApi = new RegistrationApi();
export default registrationApi;
