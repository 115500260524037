<template>
  <v-row
    justify="center"
    class="mt-5"
  >
    <v-col
      cols="12"
      sm="10"
      md="8"
      lg="6" 
    >
      <v-card ref="form">
        <v-card-title>
          Create ZITA ERP Tenant
        </v-card-title>
        <v-card-text>
          <validation-observer
            ref="valObserver"
          >
            <span class="font-weight-bold black--text"><span class="red--text"><strong>* </strong></span>Company Name:</span>
            <validation-provider
              v-slot="{ errors }"
              name="Company Name"
              rules="required"
            >
              <v-text-field
                outlined
                v-model="company.company_name"
                required
                :error-messages="errors"
                hint="Registered Business or Non-Profit Organization Name"
                persistent-hint
              />
            </validation-provider>
            <span class="font-weight-bold black--text"><span class="red--text"><strong>* </strong></span>Tenant Name:</span>
            <validation-provider
              v-slot="{ errors }"
              name="Tenant Name"
              rules="required|alpha_num|link"
            >
              <v-text-field
                outlined
                v-model="company.name"
                required
                :error-messages="errors"
                hint="Environment name that will be used for logging in"
                persistent-hint
              />
            </validation-provider>
            
            <span class="font-weight-bold black--text"><span class="red--text"><strong>* </strong></span>Link:</span>
            <validation-provider
              v-slot="{ errors }"
              name="Link"
              rules="required"
            >
              <v-text-field
                outlined
                v-model="company.name"
                required
                :error-messages="errors"
                hint="Direct link to your Zita ERP Environment"
                persistent-hint
                disabled
                suffix=".zitaert.com"
              />
            </validation-provider>
            <span class="font-weight-bold black--text"><span class="red--text"><strong>* </strong></span>Username:</span>
            <validation-provider
              v-slot="{ errors }"
              name="Company Name"
              rules="required|email"
            >
              <v-text-field
                outlined
                v-model="company.username"
                required
                :error-messages="errors"
                hint="Email address"
                persistent-hint
              />
            </validation-provider>
            <span class="font-weight-bold black--text"><span class="red--text"><strong>* </strong></span>Password:</span>
            <validation-provider
              v-slot="{ errors }"
              name="Password"
              rules="required"
            >
              <v-text-field
                outlined
                v-model="company.password"
                required
                :error-messages="errors"
                hint="6 characters minimum"
                persistent-hint
                type="password"
              />
            </validation-provider>
            <vue-recaptcha
              sitekey="6Lf3EnAdAAAAAKMQFnGd55hWxGJRKZg23Q-8r1_o"
              :load-recaptcha-script="true"
              @verify="validateCap"
            />
          </validation-observer>
        </v-card-text>
        
        <v-divider class="mt-12" />
        <span style="font-weight: bold;">IMPORTANT:</span>
        &nbsp;By submitting this form, you are agreeing with
        <v-btn
          text
          color="primary"
          @click="termsClicked"
        >
          Terms Of Use
        </v-btn> and 
        <v-btn
          text
          color="primary"
          @click="privacyClicked"
        >
          Privacy Policy
        </v-btn> of Zita ERP application.
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="success"
            @click="submit"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { required, email, alpha_num } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import RegistrationApi from '@/api/RegistrationApi.js'
import VueRecaptcha from 'vue-recaptcha';
setInteractionMode('eager')

extend('required', {
  ...required,
  message: '{_field_} can not be empty'
})

extend('alpha_num', {
  ...alpha_num,
  message: '{_field_} can not contait special characters'
})
extend('email', {
  ...email,
  message: 'Email must be valid'
})

extend('link', { 
  async validate (value) {
    return RegistrationApi.checkLink(`${value}zitaerp.com`)
  },
  message: 'Zita ERP Tenant with this name is registered. Please, try another one.'
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VueRecaptcha
  },

  data: () => ({
    showPassword: false,
    company: {
        name: '',
        username: '',
        password: '',
        company_name: '',
        link: ''
    },
    reCaptcha: false

  }),
  methods: {
      async validate(){
        
        return await this.$refs.valObserver.validate()
      },

      async submit(){
        if(!this.validate()) return
        if(!this.reCaptcha) return
        this.company.link = `${this.company.name}.zitaert.com`
        var result = await RegistrationApi.create(this.company)
        if(!result) return
        this.$router.push('/welcome')
        
      },  
      validateCap (response) {
        if(response) this.reCaptcha = true
      },
      privacyClicked(){
        this.$router.push("/privacy-policy");
      },
      termsClicked(){
        this.$router.push("/terms-of-use");
      }
    }
}

</script>