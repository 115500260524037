import Vue from "vue";
import VueRouter from "vue-router";
import Registration from "@/views/Registration"
import WelcomePage from "@/views/WelcomePage"
import TermsOfUse from "@/views/TermsOfUse"
import PrivacyPolicy from "@/views/PrivacyPolicy"
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Registration",
    component: Registration,
  },
  {
    path: "/welcome",
    name: "WelcomePage",
    component: WelcomePage,
  },
  {
    path: "/terms-of-use",
    name: "TermsOfUse",
    component: TermsOfUse,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router;
