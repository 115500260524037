var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mt-5",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"10","md":"8","lg":"6"}},[_c('v-card',{ref:"form"},[_c('v-card-title',[_vm._v(" Create ZITA ERP Tenant ")]),_c('v-card-text',[_c('validation-observer',{ref:"valObserver"},[_c('span',{staticClass:"font-weight-bold black--text"},[_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])]),_vm._v("Company Name:")]),_c('validation-provider',{attrs:{"name":"Company Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","required":"","error-messages":errors,"hint":"Registered Business or Non-Profit Organization Name","persistent-hint":""},model:{value:(_vm.company.company_name),callback:function ($$v) {_vm.$set(_vm.company, "company_name", $$v)},expression:"company.company_name"}})]}}])}),_c('span',{staticClass:"font-weight-bold black--text"},[_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])]),_vm._v("Tenant Name:")]),_c('validation-provider',{attrs:{"name":"Tenant Name","rules":"required|alpha_num|link"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","required":"","error-messages":errors,"hint":"Environment name that will be used for logging in","persistent-hint":""},model:{value:(_vm.company.name),callback:function ($$v) {_vm.$set(_vm.company, "name", $$v)},expression:"company.name"}})]}}])}),_c('span',{staticClass:"font-weight-bold black--text"},[_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])]),_vm._v("Link:")]),_c('validation-provider',{attrs:{"name":"Link","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","required":"","error-messages":errors,"hint":"Direct link to your Zita ERP Environment","persistent-hint":"","disabled":"","suffix":".zitaert.com"},model:{value:(_vm.company.name),callback:function ($$v) {_vm.$set(_vm.company, "name", $$v)},expression:"company.name"}})]}}])}),_c('span',{staticClass:"font-weight-bold black--text"},[_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])]),_vm._v("Username:")]),_c('validation-provider',{attrs:{"name":"Company Name","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","required":"","error-messages":errors,"hint":"Email address","persistent-hint":""},model:{value:(_vm.company.username),callback:function ($$v) {_vm.$set(_vm.company, "username", $$v)},expression:"company.username"}})]}}])}),_c('span',{staticClass:"font-weight-bold black--text"},[_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])]),_vm._v("Password:")]),_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","required":"","error-messages":errors,"hint":"6 characters minimum","persistent-hint":"","type":"password"},model:{value:(_vm.company.password),callback:function ($$v) {_vm.$set(_vm.company, "password", $$v)},expression:"company.password"}})]}}])}),_c('vue-recaptcha',{attrs:{"sitekey":"6Lf3EnAdAAAAAKMQFnGd55hWxGJRKZg23Q-8r1_o","load-recaptcha-script":true},on:{"verify":_vm.validateCap}})],1)],1),_c('v-divider',{staticClass:"mt-12"}),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("IMPORTANT:")]),_vm._v("  By submitting this form, you are agreeing with "),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.termsClicked}},[_vm._v(" Terms Of Use ")]),_vm._v(" and "),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.privacyClicked}},[_vm._v(" Privacy Policy ")]),_vm._v(" of Zita ERP application. "),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.submit}},[_vm._v(" Submit ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }